
<template>
  <b-container fluid>
      <div class="filter-bar">
        <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.search')">
              <b-form-input ref="searchbox" id="filter-input" v-model="usersettings.filter" type="search" :placeholder="$t('strings.typetosearch')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.branche')">
              <b-form-select name="branches" class="form-control" v-model="usersettings.currentbranche">
                <option value="-1"> {{ $t("option.all") }}</option>
                <option v-for="(branche, idx) in activebranches" :key="idx" v-bind:value="branche.id">
                  {{ branche["name"] }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        </div>
        <b-table sort-icon-left :items="hours_branche_users()" :fields="fields" :current-page="usersettings.currentPage" :per-page="perPage"
        :filter="usersettings.filter"  :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
        <template #cell(branche_id)="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span>
          <span v-html='brancheName(row.item.id)'></span>
        </template>
        <template #cell(lastname)="row">
          {{username_by_id(row.item.id)}}<br/>
          <strong>{{$t('option.'+row.item.role)}} </strong>
        </template>
        <template #cell()="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span> {{ row.value }}
        </template>
        <template #cell(pulldown)="row">
            <button  v-if="notincarousel(row.item)" class="btn btn-primary" @click="sendToCarousel(row.item)">
              <font-awesome-icon icon="exchange" /> {{ $t('buttons.sendtocarousel') }}
            </button>  

            <button v-else class="btn btn-primary" @click="confirm_delete_user(row.item)" :title="$t('labels.deleteuser')">
              <font-awesome-icon icon="trash" /> {{ $t('labels.deleteuser') }}
            </button>
        </template>
        <template #cell(photo)="row">
          <img class="profile-photo" :src="profileto(row.item.id)" />
        </template>
        <template #cell(email)="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span>
          <a :href=mailto(row.value)>{{ row.value }}</a>
        </template>
        <template #cell(mobile)="row">
          <a :href=phoneto(row.value)>{{ row.value }}</a>
        </template>
        <template #cell(hourload)="row">
          {{ row.value }}
          {{ (row.value/18.80).toFixed(2) }} %

        </template>        
      </b-table>
      <b-pagination v-model="usersettings.currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>    
      <BSCarouselOptions />
    
      </b-container>
</template>


<script>
// import axios from 'axios';
import { mapGetters, mapState } from 'vuex'
import BSCarouselOptions from '../modals/BSCarouselOptions'
import popup_modal from '../modals/PopupModal.vue'
import axios from 'axios';

// import send_whatsapp_modal from './modals/SendWhatsappModal'
// import axios from 'axios'

export default {
  name: 'carouselusers',
  data() {
    return {
      count:0,
      perPage: 10,
      sortBy: 'hourload',
      sortDesc: false,
      sortDirection: 'desc',
      selectedUser:null,
      hoursworked:null
    }
},
async mounted(){
  var vm=this
  await axios.get("/api/get_hoursbooked_last12months")
      .then(function (response) {
        vm.hoursworked=response.data
        vm.count++

      })
      .catch(function (error) {
        console.log(error);
      });
},

computed: {
  totalRows() {
      return this.branche_users.length
    },
    branche_users() {
      return this.users.filter(u =>u.role=='user')
      // .filter(user => {
      //   return (user.branche_id == this.usersettings.currentbranche || this.checkExtraBranche(user,this.usersettings.currentbranche )|| this.usersettings.currentbranche == -1) &&
      //          user.role == 'user'

      // })

    },  
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'lastname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'rating', label: this.$i18n.t('meta.waardering'), sortable: true, sortDirection: 'desc' },
        { key: 'mobile', label: this.$i18n.t('meta.phone'), },
        { key: 'hourload', label: "hourload", sortable: true, sortDirection: 'desc' },
        { key: 'email', label: this.$i18n.t('meta.email'), sortable: false, sortDirection: 'desc' },
        { key: 'branche_id', label: this.$i18n.t('labels.workfields'), sortable: true, sortDirection: 'desc' },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },

      ]
    },
    ...mapState([
        'users',
        // 'branches',
        // 'avatars',
        'planning',
        'workfields',
        'tags',
        'current_user',
        'showBlacklist',
        'companies',
        'projects',
        'usersettings',
        'carouselURL',
        'carouselPeople'
      ]),
      ...mapGetters([
        'branche_by_id',
        'user_by_id',
        'project_by_id',
        'activebranches',
        'username_by_id',
        'confirm_modal',
        'owners',
        'find_last_planning_user'
      ]),

  },

  methods:{
    mailto(id) {
      return "mailto:" + id;
    },
    phoneto(id) {
      return "tel:" + id;
    },
    notincarousel(item){
      return this.carouselPeople.filter(p =>p.remote_user_id==item.id).length==0
    },
    async sendToCarousel(user){
      let userToSend = {}
      userToSend.firstname = user.firstname
      userToSend.middlename= user.middlename
      userToSend.lastname= user.lastname
      userToSend.email= user.email
      userToSend.mobile= user.mobile
      userToSend.dob= user.birthday
      userToSend.city= user.city
      userToSend.portal= "development"
      userToSend.workfield = this.brancheName(user.id)
      userToSend.remote_user_id = user.id
      userToSend.startdate =null
      userToSend.enddate = null
      userToSend.replacement=0
      this.$store.commit('carouselUser',userToSend)

      this.$bvModal.show('BSCarouselOptionsID')

    },
    brancheName(value) {
      // console.log(value)
      var user = this.user_by_id(value)
      const branch = this.branche_by_id(user.branche_id);
      var ret = ""
      if (branch) {
        ret += "<b>" + branch.name + "</b><br>"
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == user.branche_id) {
            if (user.workfields.indexOf(this.workfields[i]["id"]) != -1 && this.workfields[i].active) {
              ret += this.workfields[i]["name"] + "<br>"
            }
          }
        }

      }
      return ret
    },  
    confirm_delete_user(user){
      console.log(user, "delete")
      this.selectedID = this.carouselPeople.filter(p=>p.remote_user_id==user.id)[0].id
      console.log(">DEL", this.selectedID)

      let message = this.$i18n.t('labels.confirmdeletehead') + this.username_by_id(user.id) + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
        alert:this.$i18n.t("validation.removecarouseluser")
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    async modalClose() {
      if (this.confirm_modal.id == 1) {
        await axios.delete(this.carouselURL+"api/people/" + this.selectedID,  {
                headers: { Authorization: `Bearer ${this.carouselToken}` }
              }
        )
        .then(() => {
          // console.log(">>>>get_carousel_people>>",response.data)
        })
        for (let i = 0; i < this.carouselPeople.length; i++) {
          if (this.carouselPeople[i].id == this.selectedID) {
            this.carouselPeople.splice(i, 1);
          }
        }
        this.$store.dispatch('get_carousel_people')
      }
    },
    myhourload(user){
      return this.hoursworked.filter(h=> h.user_id==user.id)[0]?this.hoursworked.filter(h=> h.user_id==user.id)[0]["hours"]:0

    },
    hours_branche_users(){
      this.branche_users.forEach(u => {
        u.hourload = this.myhourload(u)
        
      });
      return this.branche_users
    }

  },
  components:{BSCarouselOptions}

}

</script>
